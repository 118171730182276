<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img src="@/assets/images/icons8-cyborg-96.png" class="h-20 ml-2 inline-block" />
        خارج شدن از لیست کاربران مخرب
      </h2>
    </template>
    <template #default>
      <router-link
        :to="{ name: 'Panel', params: { id: this.$store.state.user?.id } }"
        class="
          p-3
          bg-green-100
          border-green-200
          hover:bg-green-200
          text-green-900
          font-bold
          border
          rounded-md
          mb-5
          block
        "
        v-if="!inGroups.block"
      >
        <div class="flex items-center">
          <img src="@/assets/images/icons8-checked-user-male-skin-type-7-96.png" />
          تبریک میگم شما در لیست کاربران مخرب نیستید برای رفتن به پنل اینجا کلیک کنید
        </div>
      </router-link>
      <div v-else class="p-3 bg-blueGray-100 border-blueGray-200 border rounded-md mb-5">
        <p>
          برای خارج شدن از لیست سیاه عدد 4 رقمی زیر رو وارد کرده و ارسال کنید. این کار
          برای حفظ امنیت گروه ها از رباتهای تبچی و مخرب است
        </p>
        <ErrorBox :errors="error" />
        <div class="md:w-64 mx-auto">
          <img class="p-3 mx-auto" :src="captcha" />
          <form @submit.prevent="submit">
            <MnrNumber
              :counter="false"
              v-model="captchaText"
              class="mb-4"
              placeholder="عدد 4 رقمی"
            />
            <Button type="submit" class="mb-4">ارسال</Button>
          </form>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import Button from "@/components/Button.vue";
import ErrorBox from "@/components/ErrorBox.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";

export default {
  components: {
    BodySimple,
    Button,
    ErrorBox,
    MnrNumber,
  },
  data() {
    return {
      firstLoading: true,
      error: {},
      inGroups: {},
      getCaptcha: "",
      captchaText: "",
      captcha: "",
    };
  },
  mounted() {
    let $this = this;
    $this.$axios
      .get("/api/get-user-info", {
        params: {
          id: $this.$store.state.user.id,
          getCaptcha: true,
        },
      })
      .then(function (response) {
        // console.log(response);
        $this.inGroups = response.data.inGroups;
        $this.captcha = response.data.captcha;
      })
      .catch(function (error) {
        // console.log(error);
      })
      .finally(function () {
        // always executed
        $this.firstLoading = false;
      });
  },
  methods: {
    submit() {
      let $this = this;
      $this.$axios
        .post("/api/get-user-info", {
          id: $this.$store.state.user.id,
          captchaText: $this.captchaText,
        })
        .then(function (response) {
          // console.log(response);
          $this.inGroups = response.data.inGroups;
        })
        .catch(function (error) {
          // console.log(error);
          $this.inGroups = error.data.inGroups;
          $this.captcha = error.data.captcha;
          $this.error = error.data.error;
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
  },
};
</script>
